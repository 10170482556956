import * as React from "react"
import {graphql, withPrefix} from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import PageHeaderBlock from "../components/PageHeaderBlock";
import {Carousel, Col, Container, Row} from "react-bootstrap";
import {StaticImage} from "gatsby-plugin-image";
import ArticleWidget from "../components/widgets/ArticleWidget";
import ArticleWidget2 from "../components/widgets/ArticleWidget2";
import Breadcrumbs from "../components/Breadcrumbs";

export default function SozdatSajtDlyaSto() {
    const { t } = useTranslation();

    return (
        <Layout>
            <Seo title={t('post2Title')} description={t('post2Description')} image="../posts/sozdat-sajt-dlya-sto.png" />
            <PageHeaderBlock h1="post2H1" />
            <Container fluid className="px-flex">
                <Breadcrumbs firstChild="blogPageWord" firstChildUrl={'/blog'} secondChild="seoBusiness" secondChildUrl={'/category/sayt-pod-biznes'} thirdChild="post2H1" />
                <Row className="my-3 my-xl-4 my-xxl-5">
                    <Col xxl={2} className="mb-2 mb-xl-3 mb-xxl-0 pb-1 pb-xl-0"><ArticleWidget /></Col>
                    <Col md={8} xxl={7} className="blog-post">
                        <StaticImage
                            src="../images/posts/post2/sozdat-sajt-dlya-sto1.jpg"
                            width={2560}
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt={t('post2H1')}
                            className="blog-post__img w-100 mb-2 mb-xl-3 mb-xxl-4"
                            placeholder="blurred"
                        />
                        <div dangerouslySetInnerHTML={{__html: t('post2Text')}} />
                        <Row>
                            <Col xs={9} md={10} className="d-flex justify-content-center align-items-center">
                                <ul dangerouslySetInnerHTML={{__html: t('post2Text1')}} />
                            </Col>
                            <Col xs={3} md={2} className="d-flex justify-content-center align-items-center">
                                <svg className="post-svg svg-icon mb-3 mb-xl-4 me-2 me-xl-3">
                                    <use xlinkHref={withPrefix(`sprite.svg#faq-icon`)}></use>
                                </svg>
                            </Col>
                        </Row>
                        <div dangerouslySetInnerHTML={{__html: t('post2Text2')}} />
                        <Carousel className="mb-2 mb-xl-3 mb-xxl-4 text-center">
                            <Carousel.Item>
                                <StaticImage
                                    src="../images/posts/post2/sozdat-sajt-dlya-sto-v-odesse.png"
                                    width={2543}
                                    quality={90}
                                    formats={["auto", "webp", "avif"]}
                                    alt={t('post2Text2ImgAlt')}
                                    title={t('post2Text2ImgTitle')}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <StaticImage
                                    src="../images/posts/post2/razrabotka-sajtov-dlya-sto-v-odesse.jpg"
                                    width={2543}
                                    quality={90}
                                    formats={["auto", "webp", "avif"]}
                                    alt={t('post2Text3ImgAlt')}
                                    title={t('post2Text3ImgTitle')}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <StaticImage
                                    src="../images/posts/post2/screenshot_2020-03-07-avtosalon1.png"
                                    width={2543}
                                    quality={90}
                                    formats={["auto", "webp", "avif"]}
                                    alt={t('post2Text2ImgAlt')}
                                    title={t('post2Text2ImgTitle')}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <StaticImage
                                    src="../images/posts/post2/screenshot_2020-03-07-avtosalon1.jpg"
                                    width={2543}
                                    quality={90}
                                    formats={["auto", "webp", "avif"]}
                                    alt={t('post2Text3ImgAlt')}
                                    title={t('post2Text3ImgTitle')}
                                />
                            </Carousel.Item>
                        </Carousel>
                        <div dangerouslySetInnerHTML={{__html: t('post2Text3')}} />
                    </Col>
                    <Col md={4} xxl={3}><ArticleWidget2 /></Col>
                </Row>
            </Container>
        </Layout>
    )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;